(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Person
   *
   * @description
   *
   */
  angular
      .module('neo.services')
      .factory('LicenseTeam', LicenseTeam);

  function LicenseTeam($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/teams/:teamId/licences', {teamId: '@_teamId'});
  }
}());
